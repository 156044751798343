import React from 'react'
import Layout from '../components/layouts'
import {
  Link,
  graphql
} from 'gatsby'
import { InnerPageHero } from '../components/heroes';
import * as ds from '../uiLibrary'
import { FeatureImage } from '../components';
import styled from 'styled-components'
import HeadMeta from '../components/HeadMeta'

const StyledLink = styled(Link)`
  display: inline;
  text-decoration: none;
`
const StyledA = styled.a`
  text-decoration: none;
`

const ContactPage = ({ data }) => {
  const page = data.allContactJson.edges[0].node
  return (
    <Layout>
      <HeadMeta
        pageTitle={page.pageTitle || 'Contact - Tonit'}
        pageDescription={page.pageDescription}
        socialTitle={page.socialTitle}
        socialDescription={page.socialDescription}
        socialImage={page.socialImage}
      />
      <InnerPageHero bp="bottom" url={page.heroBackground} alt="Contact Tonit Motorcycles">
        <ds.FixedWidthRow>
          <ds.Column span={8}>
            <ds.Headline fontSize={[ds.typeScale.t6, ds.typeScale.t8]}>{page.heroHeading}</ds.Headline>
            <ds.H2>{page.heroBody}</ds.H2>
          </ds.Column>
        </ds.FixedWidthRow>
      </InnerPageHero>
      <ds.Background bg={ds.colors.midnight}>
        <ds.FixedWidthRow>
          <ds.Column span={6}>
            <ds.Headline>
              {page.locationHeading}
            </ds.Headline>
            <ds.P>
              {page.streetAddress} <br />
              {page.city}, {page.province} <br />
              {page.postalCode} <br />
              {page.country}
            </ds.P>
            <ds.H2 mb={ds.spacing.s2}>
              {page.locationSubheading}
            </ds.H2>
            <StyledA href="mailto:{page.email}">
              <ds.P>
                {page.email}
              </ds.P>
            </StyledA>
          </ds.Column>
          <ds.Column span={6}>
            <FeatureImage src={page.locationImage} maxWidth={"80%"} alt="Tonit Head Office" />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
      <ds.Background bg={ds.colors.dusk}>
        <ds.FixedWidthRow>
          <ds.Column span={7}>
            <ds.H4 textAlign={['left', 'center']}>
              {page.ctaBody}
            </ds.H4>
          </ds.Column>
          <ds.Column vc span={5}>
            <StyledLink to={page.ctaButtonLink} alt={page.ctaButtonText}>
              <ds.Button>
                {page.ctaButtonText}
              </ds.Button>
            </StyledLink>
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContactJson {
      edges {
        node {
          heroHeading
          heroBody
          heroBackground
          locationHeading
          streetAddress
          city
          province
          postalCode
          country
          locationSubheading
          email
          locationImage
          ctaBody
          ctaButtonText
          ctaButtonLink
          pageTitle
          pageDescription
          socialTitle
          socialDescription
          socialImage
        }
      }
    }
  }
  
`

export default ContactPage